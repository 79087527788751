import { login, logout, signup, forgotPassword, resetPassword, confirmUser } from './auth';
import {
    getLocationsMap,
    getLocationData,
    getRecommendations,
    getExplanation,
    getLegend,
    getStaticFiles,
    getExcelExport,
} from './apiPortalData';

export {
    login,
    logout,
    signup,
    forgotPassword,
    resetPassword,
    getLocationsMap,
    getLocationData,
    getRecommendations,
    getExplanation,
    getLegend,
    getStaticFiles,
    getExcelExport,
    confirmUser,
};
